<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="1200"
      persistent
    >
      <v-card>
        <v-card-title>
          {{data.Description}} 
          <v-spacer></v-spacer>
          <v-chip
            class="mr-3"
            color="green"
            text-color="white"
            v-if="data.details && data.details.isActive == 1"
          >
            Active
          </v-chip>
          <v-chip
            class="mr-3"
            color="grey"
            text-color="white"
            v-else-if="data.details && data.details.isActive == 0"
          >
            Inactive
          </v-chip>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-card-title>
        <v-divider></v-divider>
        <!-- <v-card-text style="height: 300px;" class="d-flex align-center flex-column pt-10">
          This Panel is still under development. :-)
        </v-card-text> -->
        <v-card-text style="max-height: 900px;" class="pt-4" v-if="data.details">
          <v-row>
            <v-col cols="12" class="announcement-banner">
              <v-banner>
              {{ formatDate3(data.details.DateCreated) }}
              <h1 class="text-uppercase mt-1">{{data.details.Description}}</h1>
            </v-banner>
            </v-col>
          </v-row>
          <v-sheet color="grey lighten-3" class="my-3 pa-3 rounded">
          <v-row no-gutters>
            <v-col cols="12">
              <v-icon class="mr-2">mdi-clock-outline</v-icon><strong>Duration: </strong>{{ formatDate3(data.details.DateStart) }} - {{ formatDate3(data.details.DateEnd) }}
            </v-col>
            <v-col cols="12" v-if="data.details.DateSubmission">
              <v-icon class="mr-2">mdi-file-clock-outline</v-icon><strong>PR Submission: </strong>{{ formatDate3(data.details.DateSubmission) }}
            </v-col>
          </v-row>
        </v-sheet>
        <!-- <v-card outlined class="mb-3">
          <v-card-title>Attachments</v-card-title>
          <v-card-tex>samples</v-card-tex>
        </v-card> -->
        <v-card flat outlined v-if="data.AnnouncementTypeID == 1 || data.AnnouncementTypeID == 2">
          <v-card-title>
            Budget Codes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              color="header"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="budgets"
            :search="search"
            :loading="loading"
          >
            <template v-slot:[`item.num`]="{ index }">
              <span>{{index + 1}}</span>
            </template>
          </v-data-table>
        </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn
            color="grey darken-2"
            dark
            outlined
            class="elevation-0"
            @click="closeDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script scope>
  export default {
    props: {
        data: null,
    },
    data () {
      return {
        dialogm1: '',
        dialog: false,
        search: '',
        headers: [
          {
            text: '#',
            align: 'center',
            filterable: false,
            value: 'num',
            width: 100,
            class: "grey lighten-1 white--text text-caption font-weight-bold"
          },
          { text: 'Budget Code', value: 'BudgetCode', class: "grey lighten-1 white--text text-caption font-weight-bold" },
          { text: 'Office', value: 'OfficeDescription', class: "grey lighten-1 white--text text-caption font-weight-bold" },
          { text: 'Fund Manager', value: 'FundManager', class: "grey lighten-1 white--text text-caption font-weight-bold" },
        ],
        budgets: [],
        loading: false,
      }
    },
    watch: {
        data: {
        handler (data) {
         if(data.id){
          console.log(data)
          if((data.AnnouncementTypeID == 1 || data.AnnouncementTypeID == 2) && data.details){
            this.getBudgetDetals(data.details.Codes);
          }
            this.dialog = true;
         }
        },
        deep: true,
      },
    },
    methods: {
      getBudgetDetals(codes) {
        this.loading = true
        let data = new FormData();
        data.append("Codes", codes || '')
        this.axiosCall(
              '/get/announcedBudgetCodes',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
            this.budgets = res.data.data;
          })
      },
      closeDialog(){
        this.dialog = false;
        this.eventHub.$emit("closeViewAnnouncement", false);
      }
    }
  }
</script>